/*
 * @Author: hwu
 * @Date: 2020-03-17 00:32:05
 * @Description: 表单验证用到的一些正则表达式
 * @LastEditor: hwu
 * @LastEditTime: 2020-12-31 01:22:22
 * @EditContent: file content
 */
export default {
  weixin: () => {
    const ua = navigator.userAgent.toLowerCase()
    return ua.indexOf('micromessenger') !== -1
  },
  alipay: () => {
    const ua = navigator.userAgent.toLowerCase()
    return ua.indexOf('alipay') !== -1
  },
  /**
   * 验证手机号
   * @param {String} val 验证参数
   */
  mobile: val => {
    const rule = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
    return rule.test(val)
  },
  // 金额（大于0的两位小数）
  amount: val => {
    const rule = /(^[1-9](\d+)?(\.\d{1,2})?$)|(^\d\.\d{1,2}$)/
    return rule.test(val)
  },
  /**
   * 正整数
   * @param {String} val 验证参数
   */
  positiveInteger: val => {
    const rule = /^[1-9]\d*$/
    return rule.test(val)
  }
}
