/*
 * @Author: hwu
 * @Date: 2021-02-22 17:30:02
 * @Description: file content
 * @LastEditTime: 2023-01-16 17:23:35
 */
import axios from 'axios'

const posBaseUrl = process.env.VUE_APP_BASE_API
const crmBaseUrl = process.env.VUE_APP_CRM_API
const cpBaseUrl = process.env.VUE_APP_CP_API
const centBaseUrl = process.env.VUE_APP_CENTER_API
const mktBaseUrl = process.env.VUE_APP_MKT_API

// 创建axios实例
let httpClients = {
  posHttp: axios.create({
    baseURL: posBaseUrl,
    timeout: 60 * 1000
  }),
  crmHttp: axios.create({
    baseURL: crmBaseUrl,
    timeout: 60 * 1000
  }),
  cpHttp: axios.create({
    baseURL: cpBaseUrl,
    timeout: 60 * 1000
  }),
  centerHttp: axios.create({
    baseURL: centBaseUrl,
    timeout: 60 * 1000
  }),
  mktHttp: axios.create({
    baseURL: mktBaseUrl,
    timeout: 60 * 1000
  })
}

Object.values(httpClients).forEach(service => {
  // request拦截器
  service.interceptors.request.use(
    config => {
      // 这里可以增加全局的拦截，取缓存中的token存入header
      const userToken = localStorage.getItem('userToken')
      if (userToken && !config.noToken) {
        config.headers['token'] = userToken
      }
      return config
    },
    error => {
      Promise.reject(error)
    }
  )

  // respone拦截器
  service.interceptors.response.use(
    response => {
      return response.data
    },
    error => {
      // 这里可以增加全局的错误提示
      Promise.reject(error)
    }
  )
})

const { posHttp, crmHttp, cpHttp, centerHttp, mktHttp } = httpClients
export { posHttp, crmHttp, cpHttp, centerHttp, mktHttp }
